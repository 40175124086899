<template>
	<Modal
		title="会议附件"
		:value="visible"
		width="450"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<ul class="file-list">
			<li v-for="item in defaultList" :key="item.id">
				<span>{{ item.name }}</span>
				<a
					href="javascript:;"
					v-if="item.suffix === 'docx'"
					@click="openNewWindow(item)"
					>在线批注</a
				>
				<a href="javascript:;" v-else @click="handlePreview(item)">预览</a>
			</li>
		</ul>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'trainDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</Modal>
</template>
<script>
import BASE64 from "Base64"
import { mapState } from "vuex"
import api from "@/api/ethicalMeeting/index"
import docType from "@/config/docType"
import { getIp } from "@/api/user/user"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"

const { getMeetFiles } = api
export default {
	props: ["visible", "updateId"],
	components: {
		DocumentPreview
	},
	data() {
		return {
			loading: false,
			defaultList: [],
			documentPreviewVisible: false,
			documentSrc: "",
			ip: "",
			port: "",
			officeSrc: "http://120.92.138.226:1888"
		}
	},
	computed: {
		...mapState("user", ["info"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.getFileList()
				this.getOfficeSrc()
			}
		}
	},
	methods: {
		// 获取ip地址和端口
		async getOfficeSrc() {
			// const res = await getIp();
			// if (res && res.data) {
			//   this.officeSrc = `http://${res.data.ip}:${res.data.port}/web-apps/apps/api/documents/api.js`;
			//   // this.officeSrc = `http://20.50.54.194:1888/web-apps/apps/api/documents/api.js`;
			//   // this.officeSrc = `${httpStr}//${host}/web-apps/apps/api/documents/api.js`;
			//   console.log('officeSrc', this.officeSrc);
			// }
		},
		getFileList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getMeetFiles(this.updateId)
				if (res) {
					this.defaultList = res.data.attachments || []
					// this.ip = res.data.ip;
					// this.port = res.data.port;
				}
				this.loading = false
			})
		},
		// 在线批注
		openNewWindow(item) {
			const httpStr = window.location.protocol
			const { host } = window.location
			const newItem = {
				...item,
				userName: this.info.realName,
				userId: this.info.id,
				urlLink: `${httpStr}//${host}`,
				officeSrc: this.officeSrc
			}
			// sessionStorage.setItem("docOfficeInfo", JSON.stringify(newItem))
			// const url = '#/only-office';
			// window.open(url);

			// const fileUrl = `http://120.92.138.226:8092/office/getFile?docfileId=${newItem.id}`
      console.log(`${process.env.VUE_APP_PUBLIC_BASE_PROXY_URL}/${newItem.path}`)
			const fileUrl = `${process.env.VUE_APP_PUBLIC_BASE_PROXY_URL}/${newItem.path}`
			window.open(
				`${process.env.VUE_APP_PUBLIC_BASE_PROXY_WORD_URL}/onlinePreview?url=${encodeURIComponent(BASE64.btoa(fileUrl))}`
			)

			// '_blank',
			//   'width=1220,height=600,left=50,top=50,scrollbars=yes,toolbar=no',
		},
		// 预览
		handlePreview(file) {
			let isPreview = false
			if (file.suffix) {
				const fileType = file.suffix && file.suffix.toUpperCase()
				isPreview = docType.previewTypes.includes(fileType)
			} else {
				const type = file.type ? file.type.toUpperCase() : ""
				for (let i = 0; i < docType.previewTypes.length; i++) {
					if (type && type.indexOf(docType.previewTypes[i]) > -1) {
						isPreview = true
						break
					}
				}
			}
			if (isPreview) {
				// 预览
				this.documentSrc = `${this.$baseUrl}/document/attachment/inline/${file.id}?isTransfer=1&isIgnorePlaceholder=1&token=${this.$store.state.user.token}`
				this.documentPreviewVisible = true
			} else {
				// 下载
				window.open(
					`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
				)
			}
		},
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style scoped lang="less">
.file-list {
	list-style: none;
	li {
		display: flex;
		padding: 5px 0;
		justify-content: space-between;
		align-items: flex-end;
		border-bottom: 1px dashed #bfbfbf;
		span {
			flex-shrink: 1;
		}
		a {
			flex-basis: 60px;
			flex-shrink: 0;
		}
	}
}
</style>
