<template>
	<div class="content-wrap ethical-meeting-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="会议名称">
				<Input
					v-model="searchParams.ethicMeetingName"
					clearable
					placeholder="请输入会议名称"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="开始结束日期" placement="top">
				<DatePicker
					type="daterange"
					placeholder="请选择开始结束日期"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.status"
					:transfer="true"
					clearable
					placeholder="请选择状态"
				>
					<Option
						:value="list.value"
						v-for="(list, index) in statusList"
						:key="index"
						>{{ list.label }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				type="primary"
				v-if="systemActionPermissions.indexOf('btn_ethic_meeting_add') !== -1"
				@click="handleAdd"
				>新增</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			class="table-wrap"
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		>
			<div slot-scope="{ row }" slot="name">
				<Poptip trigger="hover" transfer class="pop-rule">
					<p class="preview-wrap" @click="e => handlePreview(e, row)">
						{{ row.name }}
					</p>
					<div slot="content" class="ethical-formitem-tooltip-content">
						{{ row.name }}
					</div>
				</Poptip>
			</div>
			<div slot-scope="{ row }" slot="meetNum">
				<span style="margin-right: 5px">{{
					row.projectVOs.length || "-"
				}}</span>
				<Poptip
					trigger="hover"
					v-if="row.projectVOs.length"
					transfer
					class="pop-rule"
				>
					<Icon size="20" type="ios-information-circle-outline" />
					<div slot="content" class="ethical-formitem-tooltip-content">
						<p
							class="pop-con"
							v-for="item in row.projectVOs"
							:key="item.projectId"
						>
							<a href="javascript:;" @click="toProjectWorkbench(item)">{{
								item.projectName || "-"
							}}</a>
						</p>
					</div>
				</Poptip>
			</div>
			<div slot-scope="{ row }" slot="meetUser">
				<span style="margin-right: 5px">{{
					row.participantVOs.length || "-"
				}}</span>
				<Poptip
					transfer
					v-if="row.participantVOs.length"
					trigger="hover"
					class="pop-rule"
				>
					<Icon size="20" type="ios-information-circle-outline" />
					<div slot="content" class="ethical-formitem-tooltip-content">
						<p class="pop-con">
							<span>{{
								row.participantVOs.map(item => item.participantName).join(",")
							}}</span>
						</p>
					</div>
				</Poptip>
			</div>
			<template slot="meetTime" slot-scope="{ row }">
				<span
					>{{ row.startTime.split(" ")[0] }}
					{{ row.startTime.split(" ")[1].slice(0, -3) }} ~
					{{ row.endTime.split(" ")[1].slice(0, -3) }}</span
				>
			</template>
			<template slot="status" slot-scope="{ row }">
				<span
					:style="`color:${row.status === 2 ? '#19be6b' : row.status === 3 ? '#666' : '#ff5722'}`"
					>{{ statusList.find(item => item.value === row.status).label }}</span
				>
			</template>
		</CtmsDataGrid>
		<!--新增会议-->
		<Add
			:visible="addVisible"
			:updateId="updateId"
			:entry="entry"
			@cancel="onAddCancel"
			@onOk="onAddOk"
		></Add>
		<!--会议附件-->
		<MeetFiles
			:visible="uploadVisible"
			:updateId="updateId"
			@onCancel="uploadVisible = false"
		></MeetFiles>
		<!--生成入会二维码-->
		<Modal
			class="qr-code-modal"
			v-model="QRcodeModal"
			@on-cancel="close"
			:fullscreen="fullscreen"
			:mask-closable="false"
		>
			<div slot="header" class="modal-header">
				<h2>扫码参与投票</h2>
				<a
					href="javascript:;"
					class="fullscreen"
					@click="fullscreen = !fullscreen"
				>
					<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
						<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
						<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
					</Tooltip>
				</a>
			</div>
			<div :class="['QR-wrap', { 'QR-full-wrap': fullscreen }]">
				<Spin v-if="uuidLoading" fix></Spin>
				<div id="qrcode"></div>
			</div>
			<div slot="footer">
				<Button type="primary" @click="close">关闭</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import api from "@/api/ethicalMeeting/index"
import { mapState } from "vuex"
import config from "@/config/options.config"
import QRcode from "qrcodejs2"
import MeetFiles from "./MeetFiles.vue"
import Add from "./Add.vue"

const { deleteEthicalMeet, getMeetList } = api
const { statusList } = config

export default {
	name: "ethicalMeeting",
	components: {
		Add,
		MeetFiles
	},
	data() {
		return {
			loading: false,
			addVisible: false,
			uploadVisible: false,
			statusList,
			listData: [],
			columns: [
				{
					title: "会议名称",
					key: "name",
					minWidth: 150,
					slot: "name"
				},
				{
					title: "上会项目数",
					key: "meetNum",
					minWidth: 120,
					slot: "meetNum"
				},
				{
					title: "会议时间",
					key: "meetTime",
					minWidth: 160,
					slot: "meetTime"
				},
				{
					title: "发起人",
					key: "initiatorName",
					minWidth: 120
				},
				{
					title: "参会人员",
					key: "meetUser",
					minWidth: 120,
					slot: "meetUser"
				},
				{
					title: "状态",
					key: "status",
					minWidth: 120,
					slot: "status"
				},
				{
					title: "操作",
					key: "action",
					fixed: "right",
					width: 250,
					renderButton: params => {
						const actionList = [
							{
								label: "入会", // 0
								on: {
									click: () => {
										if (
											params.row.initiatorId === this.info.id ||
											this.info.id === "1" ||
											this.info.id === "2"
										) {
											// 发起人视角
											this.handleInMeet(params)
										} else {
											// 参会人视角
											this.handleGenerateQRcode(params)
										}
									}
								}
							},
							{
								label: "附件", // 1
								on: {
									click: this.handleFiles
								}
							},
							{
								label: "查看", // 2
								on: {
									click: this.handleView
								}
							},
							{
								label: "编辑", // 3
								on: {
									click: this.handleEdit
								}
							},
							{
								label: "删除", // 4
								on: {
									click: this.handleDelete
								},
								confirm: {
									title: "确认删除此会议？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf("btn_ethic_meeting_join") ===
							-1
						) {
							actionList[0] = null
						}
						if (
							this.systemActionPermissions.indexOf(
								"btn_ethic_meeting_attachment"
							) === -1
						) {
							actionList[1] = null
						}
						if (
							this.systemActionPermissions.indexOf("btn_ethic_meeting_view") ===
							-1
						) {
							actionList[2] = null
						}
						// 会议发起人没有配置编辑权限，也默认有权限
						if (
							this.systemActionPermissions.indexOf("btn_ethic_meeting_edit") ===
								-1 &&
							params.row.initiatorId !== this.info.id
						) {
							actionList[3] = null
						}
						// 发起人没有配置删除权限，也默认有权限
						if (
							params.row.initiatorId !== this.info.id &&
							this.systemActionPermissions.indexOf("btn_ethic_meeting_del") ===
								-1
						) {
							actionList[4] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			times: [],
			searchParams: {
				status: "", // 状态，1-未开始，2-进行中，3-已结束
				ethicMeetingName: "",
				startDate: this.times ? this.times[0] : "",
				endDate: this.times ? this.times[1] : ""
			},
			oldSearchParams: {},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			updateId: "",
			entry: "",
			uuid: "",
			uuidLoading: false,
			QRcodeModal: false,
			fullscreen: false,
			qrcode: ""
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["info"])
	},
	created() {
		this.initList()
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getMeetList({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 新增会议
		handleAdd() {
			this.updateId = ""
			this.entry = "add"
			this.addVisible = true
		},
		// 编辑会议
		handleEdit({ row }) {
			this.updateId = row.ethicMeetingId
			this.entry = "edit"
			this.addVisible = true
		},
		// 查看会议
		handleView({ row }) {
			this.updateId = row.ethicMeetingId
			this.entry = "view"
			this.addVisible = true
		},
		// 新增，修改会议 提交
		onAddOk() {
			this.addVisible = false
			this.initList()
		},
		onAddCancel() {
			this.addVisible = false
		},
		// 删除会议
		handleDelete({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await deleteEthicalMeet(row.ethicMeetingId)
				if (res) {
					this.$Message.success("删除成功！")
					this.initList()
				}
				this.loading = false
			})
		},
		// 附件
		handleFiles({ row }) {
			this.updateId = row.ethicMeetingId
			this.uploadVisible = true
		},
		// 入会
		handleInMeet({ row }) {
			this.$router.push({
				path: "/special/inMeet",
				query: {
					id: row.ethicMeetingId
				}
			})
		},
		handleGenerateQRcode({ row }) {
			this.fullscreen = false
			this.QRcodeModal = true
			this.getQRcode(row)
		},
		// 生成二维码
		getQRcode(row) {
			let url = window.location.href.split("#")[0]
			url += "#/ethical/mobile/login"
			this.uuidLoading = true
			this.uuid = `${url}?id=${row.ethicMeetingId}`
			console.log("uuid", this.uuid)
			if (this.qrcode) {
				this.qrcode.makeCode(this.uuid)
			} else {
				this.qrcode = new QRcode("qrcode", {
					width: this.fullscreen ? 300 : 100,
					height: this.fullscreen ? 300 : 100, // 高度
					text: this.uuid // 二维码内容
				})
			}
			this.uuidLoading = false
		},
		close() {
			this.QRcodeModal = false
		},
		// 跳转到工作台
		toProjectWorkbench(row) {
			window.history.pushState({}, null, window.location.href)
			this.$router.push({ path: `/project/${row.projectId}/workbench` })
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams = {
				status: "", // 状态，1-未开始，2-进行中，3-已结束
				ethicMeetingName: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			Object.assign(this.searchParams, {
				startDate: this.times ? this.times[0] : "",
				endDate: this.times ? this.times[1] : ""
			})
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
<style lang="less">
.ethical-meeting-wrap {
	.title {
		display: inline-block;
		font-weight: 700;
		font-size: 18px;
	}
	.file-upload-btn {
		float: right;
	}
	.preview-wrap {
		cursor: pointer;
		word-break: break-all;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.table-wrap {
		.ivu-table {
			width: 100%;
		}
	}
	.pop-rule {
		/*width: 40%;*/
		vertical-align: middle;
		.ivu-poptip-rel {
			width: 100%;
		}
	}
}
.ethical-formitem-tooltip-content {
	padding: 0;
	color: #333;
	max-height: 400px;
	max-width: 500px;
	white-space: normal;
	overflow-y: auto;
	.pop-con {
		line-height: 20px;
		padding-bottom: 5px;
		span {
			/*width: 90px;*/
			display: inline-block;
		}
	}
}
.qr-code-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
/* 二维码*/
.QR-wrap {
	position: relative;
	margin: 0 auto;
	box-sizing: border-box;
	width: 220px;
	height: 220px;
	border-style: solid;
	border-width: 2px;
	border-image-source: linear-gradient(0deg, #fe8b38 0%, #fda21e 100%);
	border-image-slice: 1;

	#qrcode {
		display: inline-block;
	}

	img {
		width: 216px;
		height: 216px;
		border: 15px solid #fff;
	}
	&.QR-full-wrap {
		width: 400px;
		height: 400px;
		margin-top: 15vh;
		img {
			width: 396px;
			height: 396px;
		}
	}
}
</style>
