<template>
	<div>
		<Modal
			class="set-meeting-projects-modal"
			:value="visible"
			width="1350"
			:styles="{ top: fullscreen ? 0 : '30px' }"
			:fullscreen="fullscreen"
			:scrollable="true"
			:mask-closable="false"
			@on-cancel="onCancel"
		>
			<Spin fix v-if="loading"></Spin>
			<div slot="header" class="modal-header">
				<h2>设置上会项目</h2>
				<a
					href="javascript:;"
					class="fullscreen"
					@click="fullscreen = !fullscreen"
				>
					<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
						<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
						<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
					</Tooltip>
				</a>
			</div>
			<Row :gutter="10">
				<Col span="12">
					<div class="task-query">
						<span>项目名称：</span>
						<div class="task-input-wrapper" style="margin-right: 10px">
							<Input
								style="width: 220px"
								search
								v-model="name"
								enter-button
								placeholder="模糊查询"
								@on-search="taskChange"
							/>
						</div>
						<span>待办：</span>
						<div class="task-input-wrapper">
							<Select
								v-model="isSelf"
								@on-change="seleChange"
								style="width: 200px"
							>
								<Option :value="0">全部项目</Option>
								<Option :value="1">我的待办</Option>
							</Select>
						</div>
					</div>
					<div style="position: relative">
						<Spin fix v-if="leftLoading"></Spin>
						<CtmsDataGrid
							:height="gridHeight"
							:columns="columns"
							:data="listData"
							:page="page"
						>
							<template slot-scope="{ row }" slot="action">
								<!--:style="{color: row.isNeedMeeting === 1 ? '#19be6b' : '#2D8cF0'}"-->
								<a href="javascript:;" @click="handleMeeting(row, 0)">
									上会
									<!--{{row.isNeedMeeting === 1 ? '取消上会' : '上会'}}-->
								</a>
							</template>
						</CtmsDataGrid>
					</div>
				</Col>
				<Col span="12">
					<h3 class="checked-project">
						<span class="line"></span>已选中的上会项目
						<span class="right-text">
							（提示：对已选中的上会项目，可以进行拖拽排序）
						</span>
					</h3>
					<Row class="row-list-title">
						<Col span="4" style="text-align: center"> 序号 </Col>
						<Col span="8"> 项目名称 </Col>
						<Col span="8"> 主要研究者(PI) </Col>
						<!--<Col span="4">-->
						<!--流程名称-->
						<!--</Col>-->
						<!--<Col span="4">-->
						<!--任务名称-->
						<!--</Col>-->
						<!--<Col span="3">-->
						<!--任务创建时间-->
						<!--</Col>-->
						<Col span="4"> 操作 </Col>
					</Row>
					<div :style="{ height: `${gridHeight - 70}px`, overflow: 'auto' }">
						<draggable
							v-model="meetingList"
							v-bind="{ draggable: '.sort-item' }"
						>
							<Row
								v-for="(item, index) in meetingList"
								:key="index"
								class="sort-item"
							>
								<Col span="4" style="text-align: center"
									>项目{{ index + 1 }}
								</Col>
								<Col span="8">
									{{ item.projectName || "--" }}
								</Col>
								<Col span="8">
									{{ item.pis || "--" }}
								</Col>
								<!--<Col span="4">-->
								<!--{{item.processName || '&#45;&#45;'}}-->
								<!--</Col>-->
								<!--<Col span="4">-->
								<!--{{item.taskName || '&#45;&#45;'}}-->
								<!--</Col>-->
								<!--<Col span="3">-->
								<!--{{item.startTime || '&#45;&#45;'}}-->
								<!--</Col>-->
								<Col span="4">
									<a
										href="javascript:;"
										@click="handleMeeting(item, 1)"
										style="color: #19be6b"
										>取消上会</a
									>
								</Col>
							</Row>
						</draggable>
					</div>
				</Col>
			</Row>
			<div slot="footer">
				<Button
					:disabled="!meetingList.length"
					@click="allCancel"
					:loading="loading"
					style="margin-right: 20px"
					>取消全部上会</Button
				>
				<Button type="primary" @click="onSubmit" :loading="loading"
					>完成</Button
				>
			</div>
		</Modal>
	</div>
</template>

<script>
import draggable from "vuedraggable"
import api from "@/api/ethicalMeeting/index"
import { mapState } from "vuex"

const { getMeetingPro } = api

export default {
	name: "setMeetingProjects",
	props: ["visible", "projectList"],
	components: {
		draggable
	},
	data() {
		return {
			loading: false,
			leftLoading: false,
			fullscreen: true,
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 150,
					render: (h, { row }) => h("span", {}, row.projectName || "--")
				},
				{
					title: "主要研究者(PI)",
					key: "pis",
					minWidth: 120,
					render: (h, { row }) => h("span", {}, row.pis || "--")
				},
				/*  {
          title: '流程名称',
          key: 'processName',
          minWidth: 100,
          render: (h, { row }) => h('span', {}, row.processName || '--'),
        },
        {
          title: '任务名称',
          key: 'taskName',
          minWidth: 80,
          render: (h, { row }) => h('span', {}, row.taskName || '--'),
        },
        {
          title: '任务创建时间',
          key: 'startTime',
          minWidth: 80,
          render: (h, { row }) => h('span', {}, row.startTime || '--'),
        }, */
				{
					title: "操作",
					key: "action",
					slot: "action",
					width: 90
				}
			],
			procInstId: "",
			projectId: "",
			name: "",
			isSelf: 1,
			meetingList: []
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.meetingList = [...this.projectList]
				this.initAllList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 120
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 200
				: this.dataGridHeight
		}
	},
	methods: {
		// 初始化左侧流程实例列表
		initAllList() {
			this.$asyncDo(async () => {
				this.leftLoading = true
				let res = false
				res = await getMeetingPro({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					name: this.name,
					isSelf: this.isSelf === 1 ? 1 : "",
					filterIds: this.meetingList.map(item => item.projectId)
				})
				if (res) {
					this.listData = res.data ? res.data.list : []
					this.page.total = res.data ? res.data.total : 0
				}
				this.leftLoading = false
			})
		},
		// 上会 0 / 取消上会 1
		handleMeeting(row, status) {
			if (status === 1) {
				this.meetingList = this.meetingList.filter(
					item => item.projectId !== row.projectId
				)
			} else {
				if (this.listData.length === 1 && this.page.current > 1) {
					this.page.current = this.page.current - 1
				}
				this.meetingList.push({ ...row })
			}
			this.initAllList()
		},
		// 关闭
		onCancel() {
			this.$emit("onOk", this.projectList)
			this.fullscreen = true
			this.isSelf = 1
			this.name = ""
		},
		// 取消全部上会
		allCancel() {
			this.$Modal.confirm({
				title: "提示",
				content: "您确认要对所有项目取消上会吗？",
				onOk: () => {
					this.meetingList = []
					this.initAllList()
				}
			})
		},
		// 完成上会及排序
		onSubmit() {
			this.$emit("onOk", this.meetingList)
			this.fullscreen = true
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initAllList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initAllList()
			})
		},
		taskChange(v) {
			this.page.current = 1
			this.initAllList()
		},
		seleChange(v) {
			this.page.current = 1
			this.initAllList()
		}
	}
}
</script>

<style lang="less">
.set-meeting-projects-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.task-query {
		margin-bottom: 15px;
		display: flex;
		> span {
			font-size: 14px;
			line-height: 32px;
			display: inline-block;
		}
		.task-input-wrapper {
			display: inline-block;
			/*width: 300px;*/
		}
	}
	.ivu-modal-body {
		padding: 16px 5px;
	}
	.checked-project {
		margin-bottom: 15px;
		overflow: hidden;
		font-weight: 700;
		font-size: 16px;
		line-height: 32px;
		color: #ed4014;
		.line {
			margin-right: 10px;
			display: inline-block;
			width: 5px;
			height: 14px;
			vertical-align: middle;
			background: #ed4014;
		}
		.right-text {
			margin-left: 10px;
			color: #40a9ff;
			font-weight: normal;
			font-size: 14px;
		}
	}
	.row-list-title {
		min-height: 40px;
		padding: 10px 0;
		font-size: 14px;
		font-weight: bold;
		line-height: 20px;
		background-color: #f8f8f9;
	}
	.sort-item {
		display: flex;
		align-items: center;
		padding: 10px 0;
		width: 100%;
		border-bottom: 1px solid #e8eaec;
		background: #fff;
		&:hover {
			background: #edfdf3;
		}
		.ivu-col {
			font-size: 14px;
			padding: 8px 6px;
		}
	}
}
</style>
